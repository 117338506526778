/* eslint-disable max-lines */
import { Input, Button } from '@nazka/nazka.mapframe.components'
import { useEffect, useState } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';

import './home-page.styl'
import './tags-input.styl'
import { useSelector } from 'react-redux';
import InteractiveMap from '../InteractiveMap/InteractiveMap';
import Results from './Results';

function HomePage() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null)
  const [imageURL, setImageURL] = useState(null)
  const [title, setTitle] = useState(null)
  const [location, setLocation] = useState(null)
  const [communityResource, setCommunityResource] = useState(null)
  const [adaptation, setAdaptation] = useState(null)
  const [marker, setMarker] = useState(null);

  const [mapIsExpanded, setMapIsExpanded] = useState(false)
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const map = useSelector(state => state.mapboxMap.map)
  const [anserIsSubmitted, setAnswerIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (map) {
      map.on('click', (e) => {
        const { lng, lat } = e.lngLat;

        // Remove previous marker
        if (marker) {
          marker.remove();
        }

        // Add a new marker
        const newMarker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(map);

        setMarker(newMarker);
        setLocation({ lat, lng });
      })
    }
  }, [map, marker])

  const handleFileChange = (e) => {
    // Uploaded file
    const me = e.target.files[0];
    setFileName(me.name)
    // Changing file state
    setFile(me);
  };

  const uploadEntry = async () => {
    if (!file) {
      return;
    }
    setIsLoading(true)
    const formData = new FormData();
    formData.append('image', file);

    try {
      const { data } = await axios.post('/api/uploadimage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data) {
        setImageURL(data.data.Location)
        const final = {
          title,
          image: data.data.Location,
          location,
          linkedHazard: tags,
          communityResource,
          adaptationPlan: adaptation,
        }
        const newAnswer = await axios.post('/api/createnewanswer', final)
        if (newAnswer) {
          setAnswerIsSubmitted(true)
          setIsLoading(false)
        }
        setAnswerIsSubmitted(true)
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  // const uploadEntry = () => {
  //   uploadFile().then(() => {
  //     if (imageURL) {
        
  //       console.log(final)
  //     }
  //   })
    
  //   // setAnswerIsSubmitted(true)
  // }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if ((e.key === 'Enter' && inputValue.trim() !== '')) {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    } else if (e === 'clicked') {
      setTags([...tags, inputValue.trim()]);
      setInputValue('');
    } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
      const newTags = [...tags];
      newTags.pop();
      setTags(newTags);
    }
  };

  const removeTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };
  
  return (
    <div className="home">
      {anserIsSubmitted
        ? (
          <Results
            title={title}
            imageURL={imageURL}
            location={location}
            communityResource={communityResource}
            linkedHazard={tags}
            adaptation={adaptation}
          />
        ) : (
          <div className="home__question__wrapper">
            <h2>Adaptation Action</h2>
            <div className="home__title__wrapper">
              <span>
                Title
              </span>
              <Input
                css={{
                  borderRadius: '9px',
                  border: '1px solid #BBB',
                }}
                rootElProps={{ placeholder: 'Enter title' }}
                className="no"
                onInputChange={e => setTitle(e.target.value)}
              />
            </div>
            <div className="home__image_wrapper">
              <span>
                Image
              </span>
              <Input
                color="#0065FF"
                className="image-upload"
                type="file"
                rootElProps={{ hidden: true, id: 'image-upload' }}
                onInputChange={handleFileChange}
              />
              <div className="home_uplpad-lable__wrapper">
                <label htmlFor="image-upload" className="home__upload-butn">Upload</label> 
                <span>{fileName}</span>
              </div>
          
            </div>
            <div className="home__image_wrapper">
              <span>
                Location
              </span>
              <Input
                css={{
                  borderRadius: '9px',
                  border: '1px solid #BBB',
                }}
                disabled={mapIsExpanded}
                rootElProps={{ placeholder: 'Enter your answer' }}
                className="no"
                onInputChange={e => setLocation(e.target.value)}
              />
              <Button
                onClick={() => setMapIsExpanded(prevState => !prevState)}
                layout="ghost"
                hover={false}
                css={{
                  display: 'flex',
                  padding: 0,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {mapIsExpanded ? 'exit map' : 'Or select location directly on the map'} 
              </Button>
              {mapIsExpanded && (<InteractiveMap />)}
          
            </div>
            <div className="home__image_wrapper">
              <span>
                New community resource or linked community resource?
              </span>
              <Input
                css={{
                  borderRadius: '9px',
                  border: '1px solid #BBB',
                }}
                rootElProps={{ placeholder: 'Enter your answer' }}
                className="no"
                onInputChange={e => setCommunityResource(e.target.value)}
              />
            </div>
            <div className="home__image_wrapper">
              <span>
                Linked hazard
              </span>
              {/* <Input
            width="800px"
            css={{
              borderRadius: '9px',
              border: '1px solid #BBB',
            }}
            rootElProps={{ placeholder: 'Enter hazard' }}
            className="no"
          /> */}
              <div className="tags-input-container">
                <div className="tags-input">
                  {tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                      <button onClick={() => removeTag(index)} className="remove-tag">
                        &times;
                      </button>
                    </span>
                  ))}
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    className="tags-input-field"
                    placeholder="Type and press Enter to add tags"
                  />
                </div>
              </div>
              <Button
                color="#0065FF"
                css={{
                  borderRadius: '12px',
                  fontSize: '12px',
                }}
                onClick={() => handleInputKeyDown('clicked')}
              >
                Add Hazard
              </Button>
            </div>
            <div className="home__image_wrapper">
              <span>
                What is the adaptation and how does it address the hazard?
              </span>
              <textarea
                className="home__textarea"
                placeholder="Enter your message here"
                onChange={e => setAdaptation(e.target.value)}
              />
            </div>
            <div className="home__submit__buttons">
              <Button
                color="#0065FF"
                css={{
                  borderRadius: '12px',
                  fontSize: '12px',
                }}
                onClick={uploadEntry}
                isLoading={isLoading}
                loaderProps={{
                  css: {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
    </div>
  )
}

export default HomePage