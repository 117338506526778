import { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';

import TranslationsProvider, { LocalizedRoutes } from '@utils/i18n/i18n';
import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import Cookies from '@utils/Cookies/Cookies';

import routes from '@App/app.routes';
import store from '@App/app.store';
import translator from '@App/app.translator';

import theme from '@css/theme.style.json';

import './app.styl';

function App() {
  useEffect(() => document.documentElement.setAttribute('data-app-initialised', true), []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ErrorBoundary>
            <TranslationsProvider translator={translator}>
              <LocalizedRoutes>
                {routes.map(({ path, element }) => <Route path={path} element={element} />)}
              </LocalizedRoutes>
              <AppWindow />
              <Cookies useConfig />
            </TranslationsProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
