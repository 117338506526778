import { useState, useEffect } from 'react';

import { toggleControl } from './more-toggle.utils';

// import './more-toggle.styl';

function MoreToggle(props) {
  const { controlRefs, map } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => controlRefs.forEach(curr => map.addControl(curr.ref, curr.position)));
  }, [controlRefs, map]);

  useEffect(() => {
    setTimeout(() => toggleControl(controlRefs, !isOpen));
  }, [controlRefs, isOpen]);

  return (
    <div>
      <button 
        className={`mapboxgl-ctrl-more__button ${isOpen ? 'is-open' : ''}`} 
        type="button" 
        aria-label="More controls"
        title="More Controls"
        onClick={() => setIsOpen(!isOpen)}
      />
    </div>
  );
}

export default MoreToggle;