import mapboxgl from 'mapbox-gl';

import style from './style/style.base.json';
import sources from './style/style.sources.json';
import layers from './style/style.layers.json';

/*  Exports
    ======================================================================================= */
function addSourcesAndLayersFromConfigToMap(mapboxMap) {
  Object.keys(sources).forEach(sourceId => mapboxMap.addSource(sourceId, sources[sourceId]));
  layers.forEach(layer => mapboxMap.addLayer(layer));

  return mapboxMap;
}

function mapFactory(mapEl) {
  mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;
  
  const options = { 
    container: mapEl,
    attributionControl: false,
    style,
    center: [
      33.79124316174068,
      -14.00787489043654,
    ],
    zoom: 5,
  };
  
  return new mapboxgl.Map(options);
}

export {
  mapFactory,
  addSourcesAndLayersFromConfigToMap,
}