import HomePage from '@App/pages/Home/HomePage';

const routes = [
  {
    name: 'Page Not Found', 
    path: '*', 
    element: <HomePage />,
  },
];

export default routes;