const controls = [
  {
    identifier: 'navigation',
    position: 'top-right',
    options: {
      showCompass: false,
      showZoom: true,
    },
  },
  {
    identifier: 'geolocate',
    collapsed: false,
    position: 'top-right',
    options: {
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    },
  },
  {
    identifier: 'marker',
    position: 'top-right',
    options: {},
  },
  {
    identifier: 'attribution',
    position: 'bottom-right',
    options: {
      compact: true,
      customAttribution: 'Powered by <a href="https://nazka.be" rel="noopener" target="_blank">Nazka Mapps</a>',
    },
  },
]

export {
  controls,
}