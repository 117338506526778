import MapboxMap from '@library/MapboxMap/MapboxMap'
import { useState } from 'react';

import './interactive-map.styl'

function InteractiveMap() {
  const [, setIsMapLoading] = useState(true);
  const [mapResizeId] = useState();

  return (
    <div className="interactive-map">
      <MapboxMap
        mapResizeId={mapResizeId}
        onMapInitialized={() => setIsMapLoading(false)}
      />
    </div>
  )
}

export default InteractiveMap