import { configureStore } from '@reduxjs/toolkit';
import appWindow from '@utils/AppWindow/app-window.slice';
import cookies from '@utils/Cookies/cookies.slice';
import mapboxMap from '@library/MapboxMap/mapbox-map.redux.reducers';

const createStoreArgs = [];

if (import.meta.env.DEV) {
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry.error,
  });

  createStoreArgs.push(logger);
}

const store = configureStore({ 
  reducer: {
    appWindow,
    cookies,
    mapboxMap,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(createStoreArgs),
});

export default store
