function setMapInRedux(map) {
  return {
    type: 'SET_MAP',
    payload: map,
  };
}

export {
  setMapInRedux,
}
