import mapboxgl from 'mapbox-gl';

import { controls } from '../controls.config';
import MoreControl from './CustomControls/MoreControl';

async function mapToControlRef(control) {
  const {
    identifier,
    collapsed = false,
    options = {},
    position = 'top-right',
  } = control;

  const controlRef = {
    identifier,
    collapsed,
    position,
  }

  switch (identifier) {
    case 'navigation':
      return { 
        ...controlRef, 
        ref: new mapboxgl.NavigationControl(options),
      };
    case 'geolocate':
      return { 
        ...controlRef, 
        ref: new mapboxgl.GeolocateControl(options),
      };
    case 'attribution':
      return {
        ...controlRef,
        ref: new mapboxgl.AttributionControl(options),
      }
    default: 
      return null;
  }
}

function addMoreControlRefsToMap(map, refs) {
  const topLeftRefs = refs.filter(curr => curr.position === 'top-left');
  const topRightRefs = refs.filter(curr => curr.position === 'top-right');
  const bottomLeftRefs = refs.filter(curr => curr.position === 'bottom-left');
  const bottomRightRefs = refs.filter(curr => curr.position === 'bottom-right');

  if (topLeftRefs.length) {
    map.addControl(new MoreControl({ controlRefs: topLeftRefs }), 'top-left');
  }
  
  if (topRightRefs.length) {
    map.addControl(new MoreControl({ controlRefs: topRightRefs }), 'top-right');
  }

  if (bottomLeftRefs.length) {
    map.addControl(new MoreControl({ controlRefs: bottomLeftRefs }), 'bottom-left');
  }

  if (bottomRightRefs.length) {
    map.addControl(new MoreControl({ controlRefs: bottomRightRefs }), 'bottom-right');
  }
}

/*  EXPORTS
    ================================================================================ */
async function addControlsToMap(map) {
  const controlRefs = await Promise.all(
    controls.filter(curr => !curr.collapsed).map(mapToControlRef),
  );
  const moreControlRefs = await Promise.all(
    controls.filter(curr => curr.collapsed).map(mapToControlRef),
  );

  controlRefs.forEach((curr) => {
    const { ref, position } = curr;
    
    map.addControl(ref, position);
  });

  addMoreControlRefsToMap(map, moreControlRefs);

  return [...controlRefs, ...moreControlRefs]
}

export {
  addControlsToMap,
}