import { useEffect } from 'react'
import './result.styl'

import mapboxgl from 'mapbox-gl';

function Results({
  title,
  imageURL,
  location,
  linkedHazard,
  communityResource,
  adaptation,
}) {
  // const [map, setMap] = useState(null)

  useEffect(() => {
    mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;
    if (location && typeof (location) === 'object') {
      const locationMap = new mapboxgl.Map({
        container: 'location-map',
        attributionControl: false,
        center: [
          33.79124316174068,
          -14.00787489043654,
        ],
        zoom: 5,
        interactive: false,
      });

      locationMap.on('load', () => {
        // setMap(locationMap);
        const { lng, lat } = location;
        new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(locationMap);
      
        locationMap.flyTo({
          center: [lng, lat],
          zoom: 14,
          essential: true,
        });
      });

      return () => {
        if (locationMap) {
          // setMap(null)
          locationMap.remove();
        }
      };
    }
  }, [location]);
  
  return (
    <div className="result">
      <h2>
        {title}
      </h2>
      <img src={imageURL} alt="image of entry" className="result__image" />
      <div className="result__location__wrapper">
        {location && typeof (location) !== 'string' ? (
          <div id="location-map" />
        ) : (
          <div className="result__location">
            <span>
              Location:
            </span>
            <span>
              {location} 
            </span>
          </div>
          
        )}
        
      </div>
      <div className="result__community">
        <span>
          community Resources:
        </span>
        <span>
          {communityResource}
          {' '}
        </span>
      </div>
      
      <div className="result__hazard">
        <span>LinkedHazard:</span>
        {linkedHazard.map(x => (
          <li>{x}</li>
        ))}
      </div>
      <div className="result__adaption">
        <span>
          Adaptaion plan:
        
        </span>
        <span>{adaptation}</span>
      </div>
      
    </div>
  )
}

export default Results