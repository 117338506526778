import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { usePrev } from '@services/hooks.service';
import Controls from './controls/Controls/Controls';
import { mapFactory, addSourcesAndLayersFromConfigToMap } from './mapbox-map.factory';
import { resizeMap } from './mapbox-map.utils';

import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox-map.styl';
import { setMapInRedux } from './mapbox-map.redux.actions'

function MapboxMap(props) {
  const dispatch = useDispatch()
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const { mapResizeId, onMapInitialized } = props
  const prevMapResizeId = usePrev(mapResizeId)
  const prevMap = usePrev(map)

  useEffect(() => {
    setMap(mapFactory(mapRef.current))
  }, [])

  useEffect(() => {
    if (map && (map !== prevMap)) {
      map.on('load', () => {
        addSourcesAndLayersFromConfigToMap(map)
        dispatch(setMapInRedux(map));
      });
      onMapInitialized();
    }
  }, [dispatch, map, onMapInitialized, prevMap]);

  useEffect(() => {
    if ((mapResizeId !== prevMapResizeId) && map) {
      resizeMap(map);
    }
  }, [map, mapResizeId, prevMapResizeId]);

  return (
    <div ref={mapRef} className="mapbox-map">
      <Controls map={map} />
    </div>
  );
}

export default MapboxMap;
